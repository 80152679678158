import type { Action } from "@/types";
import {
  SET_CHANNEL_VIEW,
  SET_KOTS_APP_CHANNELS,
  SET_KUBERNETES_CHANNEL_INSTALLER_HISTORY
} from "./actions";

const initialState = {
  appChannels: [],
  channelView: "grid",
  kubernetesInstallers: [],
  kubernetesInstallersCount: 0
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_KOTS_APP_CHANNELS:
      return Object.assign({}, state, {
        appChannels: action.payload.channels,
        enterpriseChannels: action.payload.enterpriseChannels,
        totalCount: action.payload.totalCount
      });
    case SET_CHANNEL_VIEW:
      return Object.assign({}, state, {
        channelView: action.payload
      });
    case SET_KUBERNETES_CHANNEL_INSTALLER_HISTORY:
      return Object.assign({}, state, {
        kubernetesInstallers: action.payload.kubernetesInstallers,
        kubernetesInstallersCount: action.payload.kubernetesInstallersCount
      });
    default:
      return state;
  }
}

import "isomorphic-fetch";

import {
  loadingData,
  forbiddenData,
  errorData,
  displayModal
} from "../../ui/main/actions";
import { logOutUser } from "../../auth/sessions/actions";
import { SessionManager } from "../../../services/SessionManager";

export const SET_KOTS_APP_CHANNELS = "SET_KOTS_APP_CHANNELS";
export const SET_CHANNEL_VIEW = "SET_CHANNEL_VIEW";
export const SET_KUBERNETES_CHANNEL_INSTALLER_HISTORY =
  "SET_KUBERNETES_CHANNEL_INSTALLER_HISTORY";

function setKotsAppChannels(appChannels) {
  return {
    type: SET_KOTS_APP_CHANNELS,
    payload: {
      channels: appChannels.channels,
      enterpriseChannels: appChannels.enterprise_channels,
      totalCount: appChannels.totalCount
    }
  };
}

function setKubernetesChannelInstallerHistory(payload) {
  return {
    type: SET_KUBERNETES_CHANNEL_INSTALLER_HISTORY,
    payload: {
      kubernetesInstallers: payload.installers,
      kubernetesInstallersCount: payload.totalCount
    }
  };
}

export function getKotsAppChannels(
  appId: string,
  excludeDetail?: boolean,
  excludeAdoption?: boolean,
  startIndex?: number,
  pageCount?: number,
  channelNameFilter?: string
) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("kotsAppChannels", true));
    let response;
    try {
      let url = `${SessionManager.getApiEndpoint("3")}/app/${appId}/channels`;

      const params = new URLSearchParams();
      if (startIndex && pageCount) {
        params.append("currentPage", startIndex.toString());
        params.append("pageSize", pageCount.toString());
      }
      if (excludeDetail || excludeAdoption) {
        params.append("excludeDetail", excludeDetail.toString());
        params.append("excludeAdoption", excludeAdoption.toString());
      }
      if (channelNameFilter) {
        params.append("channelNameFilter", channelNameFilter);
      }
      if (params.toString()) {
        url += `?${params.toString()}`;
      }

      response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json"
        },
        mode: "cors"
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(loadingData("kotsAppChannels", false));
          dispatch(forbiddenData("kotsAppChannels", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }
      const body = await response.json();
      dispatch(setKotsAppChannels(body));
      dispatch(forbiddenData("kotsAppChannels", false));
      dispatch(loadingData("kotsAppChannels", false));
      dispatch(errorData("kotsAppChannels", false));
    } catch (error) {
      dispatch(loadingData("kotsAppChannels", false));
      dispatch(errorData("kotsAppChannels", true));
      console.log(error);
      return;
    }
  };
}

export function createKotsChannel(appId, payload) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("createKotsChannel", true));

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint("3")}/app/${appId}/channel`;

      response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }

      dispatch(getKotsAppChannels(appId, false));
      dispatch(loadingData("createKotsChannel", false));
    } catch (error) {
      dispatch(loadingData("createKotsChannel", false));
      console.log(error);
      return;
    }
  };
}

export function archiveKotsChannel(appId, channelId) {
  return async (dispatch, getState) => {
    if (!appId) {
      return;
    }

    dispatch(loadingData("archiveKotsChannel", true));

    let response;
    try {
      const url = `${SessionManager.getApiEndpoint(
        "3"
      )}/app/${appId}/channel/${channelId}`;

      response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: getState().auth.sessions.sessionData.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          dispatch(logOutUser());
        }
        if (response.status === 403) {
          dispatch(displayModal("permissions", true));
        }
        if (response.status === 400) {
          const respJson = await response.json();
          if (respJson.message) {
            throw new Error(respJson.message);
          }
        }
        throw new Error(`Unexpected response status code ${response.status}`);
      }

      dispatch(getKotsAppChannels(appId, false));
    } finally {
      dispatch(loadingData("archiveKotsChannel", false));
    }
  };
}

export function setChannelView(view) {
  return {
    type: SET_CHANNEL_VIEW,
    payload: view
  };
}

const fetchChannelInstallers = async (
  dispatch,
  getState,
  { appId, channelId, pageSize, startIndex }
) => {
  const url = `${SessionManager.getApiEndpoint(
    "3"
  )}/app/${appId}/channel/${channelId}/installers?pageSize=${pageSize}&currentPage=${startIndex}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: getState().auth.sessions.sessionData.accessToken,
      Accept: "application/json"
    },
    mode: "cors"
  });

  if (!response.ok) {
    if (response.status === 401) {
      dispatch(logOutUser());
    }
    if (response.status === 403) {
      dispatch(displayModal("permissions", true));
    }
    dispatch(loadingData("kubernetesChannelInstallerHistory", false));
    throw new Error(`Unexpected response status code ${response.status}`);
  }
  return response;
};

export function getKubernetesChannelInstallerHistory({
  appId,
  channelId,
  startIndex = 0,
  pageSize = 20
}) {
  return async (dispatch, getState) => {
    if (!appId || !channelId) {
      return;
    }
    dispatch(loadingData("kubernetesChannelInstallerHistory", true));
    try {
      // This extraneous call passes `pageSize` of `0` to get all installers,
      // which is the only way to get a full count currently. This is necessary
      // for pagination. We may be able to split this to a separate call, but
      // the right solution is a proper endpoint to get the count.
      const countResponse = await fetchChannelInstallers(dispatch, getState, {
        appId,
        channelId,
        pageSize: 0,
        startIndex: 0
      });
      const pageResponse = await fetchChannelInstallers(dispatch, getState, {
        appId,
        channelId,
        pageSize,
        startIndex
      });
      const allInstallersBody = await countResponse.json();
      const allInstallersCount = allInstallersBody.installers?.length || 0;
      const pageInstallersBody = await pageResponse.json();
      dispatch(
        setKubernetesChannelInstallerHistory({
          installers: pageInstallersBody.installers,
          totalCount: allInstallersCount
        })
      );
      dispatch(loadingData("kubernetesChannelInstallerHistory", false));
    } catch (error) {
      dispatch(loadingData("kubernetesChannelInstallerHistory", false));
      throw new Error(error);
    }
  };
}

import { loader } from '@monaco-editor/react';

// Configure Monaco Editor to use locally bundled version instead of CDN
loader.config({
  monaco: window.monaco, // Use Monaco instance that will be bundled by Vite
  paths: {
    // Tell Monaco to not try loading from external source
    vs: null
  },
  'vs/nls': {
    availableLanguages: { '*': 'en' }
  }
});

// No default export to avoid the linter error
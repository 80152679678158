import asyncComponent from "../hoc/asyncComponent";
import { VendorUtilities } from "../../utilities/VendorUtilities";

export const AsyncAppsTroubleshootContainer = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/AppsTroubleshootContainer"));
});

export const AsyncApps = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/Apps"));
});

export const AsyncAuditLogPage = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/AuditLog"));
});

export const AsyncTeamSettingsComponent = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/TeamSettings"));
});

export const AsyncTroubleshootContainer = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../troubleshoot/TroubleshootContainer"));
});

export const AsyncCreateApp = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/CreateApp"));
});

export const AsyncAccountSettings = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/AccountSettings"));
});

export const AsyncCLILogin = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../cli_login/CLILogin"));
});

export const AsyncChartsmithLogin = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../chartsmith_login/ChartsmithLogin"));
});

export const AsyncSupport = asyncComponent(() => {
  return VendorUtilities.retry(() => import("../containers/Support"));
});

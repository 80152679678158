// @ts-strict
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/client";
import "@/fonts";
import Main from "@/components/main/Main";
import { theme } from "@/styles";
import "@/index.css";
import "@/scss/index.scss";
import "react-loading-skeleton/dist/skeleton.css";

import { getStore } from "@/redux/AppStore";
import ReplicatedErrorBoundary from "@/components/shared/ErrorBoundary";
import getApolloClient from "@/get-vendor-client";
import { getQueryClient } from "@/query-client";
import { AppStateProvider } from "@/context/app-state";
import { ReduxQueryProvider } from "@/query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ClusterProvider } from "./components/compatibility_matrix/ClusterContext";

const Root = () => {
  const store = getStore();
  const queryClient = getQueryClient();
  const apolloClient = getApolloClient(store);

  // If there's no bugsnag api key, then use the ReplicatedErrorBoundary
  if (import.meta.env.VITE_BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
      appVersion: import.meta.env.VITE_VENDOR_WEB_BUILD_VERSION,
      releaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE,
      enabledReleaseStages: ["production", "staging"],
      plugins: [new BugsnagPluginReact()]
    });
  }
  const ErrorBoundary =
    Bugsnag.getPlugin("react")?.createErrorBoundary(React) || ReplicatedErrorBoundary;

  return (
    <div className="u-minHeight--full u-minWidth--full flex-column flex1">
      <Helmet>
        <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <meta
          httpEquiv="Content-Security-Policy"
          content={`default-src 'self' https://*.replicated.com https://*.okteto.repldev.com wss://*.okteto.repldev.com blob: *.retraced.io notify.bugsnag.com 'unsafe-inline';
            frame-src 'self' https://tf-replicated-staging-support-bundles.s3.amazonaws.com/ https://tf-replicated-support-bundles.s3.amazonaws.com/ https://*.replicated.com https://*.okteto.repldev.com/ https://*.replicated.com https://s3.amazonaws.com/airgap.staging.replicated.com/ https://s3.amazonaws.com/airgap.replicated.com/;
            script-src 'self' https://unpkg.com 'unsafe-inline' 'unsafe-eval' *.retraced.io algolia.net algolianet.com d2wy8f7a9ursnm.cloudfront.net https://replicated.trackinglibrary.prodperfect.com;
            img-src 'self' data: s.gravatar.com https://logo.clearbit.com https://tf-replicated-customer-avatars.s3.amazonaws.com/ https://tf-replicated-staging-customer-avatars.s3.amazonaws.com/;
            media-src 'self';
            connect-src 'self' data: https://*.replicated.com wss://*.okteto.repldev.com https://*.okteto.repldev.com *.retraced.io https://browser-intake-datadoghq.com https://*.algolianet.com https://*.algolia.net https://*.pusher.com wss://*.pusher.com wss://*.replicated.com https://replicated.datapipe.prodperfect.com https://autocomplete.clearbit.com https://tf-replicated-staging-support-bundles.s3.amazonaws.com/ https://tf-replicated-support-bundles.s3.amazonaws.com/ https://g.staging.replicated.com/ https://g.replicated.com/ https://tf-replicated-staging-customer-avatars.s3.amazonaws.com/ https://tf-replicated-customer-avatars.s3.amazonaws.com/ https://*.bugsnag.com/ https://staging.kurl.sh https://kurl.sh https://staging.replicated.app https://replicated.app;
            font-src 'self' fonts.gstatic.com;
            form-action 'self' https://*.replicated.com https://*.okteto.repldev.com;
            worker-src 'self' blob: https://*.replicated.com https://*.okteto.repldev.com;
            report-uri https://browser-intake-datadoghq.com`}
        />
        <title>Replicated</title>
      </Helmet>

      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ApolloProvider client={apolloClient}>
          <ReduxProvider store={store}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <ClusterProvider>
                  <QueryParamProvider
                    adapter={ReactRouter5Adapter}
                    options={{
                      searchStringToObject: queryString.parse,
                      objectToSearchString: queryString.stringify,
                      removeDefaultsFromUrl: true
                    }}
                  >
                    <ErrorBoundary FallbackComponent={ReplicatedErrorBoundary}>
                      <AppStateProvider>
                        <ReduxQueryProvider>
                          <Main />
                        </ReduxQueryProvider>
                      </AppStateProvider>
                    </ErrorBoundary>
                  </QueryParamProvider>
                </ClusterProvider>
              </BrowserRouter>
            </ThemeProvider>
          </ReduxProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </div>
  );
};
export default Root;

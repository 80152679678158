// Import Monaco directly so it gets bundled
import * as monaco from 'monaco-editor';

// Only load workers if we're in a browser environment
if (typeof window !== 'undefined') {
  // Define workers - using dynamic imports to avoid problems during SSR or testing
  const loadWorkers = async () => {
    try {
      const editorWorker = await import('monaco-editor/esm/vs/editor/editor.worker?worker');
      const jsonWorker = await import('monaco-editor/esm/vs/language/json/json.worker?worker');
      const cssWorker = await import('monaco-editor/esm/vs/language/css/css.worker?worker');
      const htmlWorker = await import('monaco-editor/esm/vs/language/html/html.worker?worker');
      const tsWorker = await import('monaco-editor/esm/vs/language/typescript/ts.worker?worker');

      // Make Monaco available globally
      window.monaco = monaco;

      // Register Monaco's web workers
      self.MonacoEnvironment = {
        getWorker(_, label) {
          if (label === 'json') {
            return new jsonWorker.default();
          }
          if (label === 'css' || label === 'scss' || label === 'less') {
            return new cssWorker.default();
          }
          if (label === 'html' || label === 'handlebars' || label === 'razor') {
            return new htmlWorker.default();
          }
          if (label === 'typescript' || label === 'javascript') {
            return new tsWorker.default();
          }
          return new editorWorker.default();
        }
      };
    } catch (error) {
      console.warn('Monaco editor workers could not be loaded:', error);
      
      // Fallback - at least make Monaco available globally
      window.monaco = monaco;
      
      // Set a simple environment that won't try to load workers
      self.MonacoEnvironment = {
        getWorker() {
          return null;
        }
      };
    }
  };

  // Load the workers
  loadWorkers();
}

// No default export to avoid the linter error 
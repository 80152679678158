import Bugsnag from "@bugsnag/js";
import { Tarball, TarEntry } from "@obsidize/tar-browserify";
import { Buffer } from "buffer";
import { format as formatDate } from "date-fns";
import { JWTPayload, decodeJwt } from "jose";
import * as yaml from "js-yaml";
import { startCase, isEqual } from "lodash";
import { find, has, isEmpty, map, sortBy, sum, values } from "lodash-es";
import globToRegExp from "glob-to-regexp";
import moment from "moment";
import pako, { gzip } from "pako";
import queryString from "query-string";
import { AnyAction } from "redux";

import { endpoints } from "@/environment";
import { getQueryClient } from "@/query-client";
import { logOutUser } from "@/redux/auth/sessions/actions";
import { FeaturesResponse } from "@/types";
import { getStore } from "../redux/AppStore";

type Session = JWTPayload & { [key: string]: any };

export const VendorUtilities = {
  isAppPlatformOrShip(appSlug) {
    // This will return "ship" if the app is anything other than native
    return getStore().getState().data.main.apps.appsBySlug[appSlug]
      ? "platform"
      : "ship";
  },

  disableUnlimitedLicenses(licenseType, entitlements) {
    return licenseType === "trial" && entitlements.trial_license_max_days > 0;
  },

  getLatestPermissibleExpiryDate(entitlement, type) {
    const teamTrialExpirationDate = moment(entitlement.trial_expiration_date);
    const maxLicenseExpirationDate = moment().utc().add(
      entitlement.trial_license_max_days,
      "days"
    );

    let dateLimit;
    if (entitlement.is_trial_enabled) {
      if (entitlement.trial_license_max_days > 0) {
        if (teamTrialExpirationDate.isAfter(maxLicenseExpirationDate)) {
          dateLimit = maxLicenseExpirationDate;
        } else {
          dateLimit = teamTrialExpirationDate;
        }
      } else {
        dateLimit = teamTrialExpirationDate;
      }
    } else if (entitlement.trial_license_max_days > 0 && type && type === "trial") {
      dateLimit = maxLicenseExpirationDate;
    } else {
      dateLimit = null;
    }
    return dateLimit;
  },

  calculateSelectedDatePickerValue(state, entitlement, type) {
    if (state == null) {
      return moment().utc();
    }

    if (type !== "trial" && state !== null) {
      return moment.utc(state);
    } else {
      const dateLimit = this.getLatestPermissibleExpiryDate(entitlement, type) || state;
      const stateDiff = state.diff(dateLimit);
      if (stateDiff <= 0) {
        return state;
      } else {
        return moment.utc(dateLimit);
      }
    }
  },

  getToken() {
    return getStore()?.getState?.().auth.sessions.sessionData.accessToken;
  },

  isGoogleSignup() {
    return getStore()?.getState().auth.sessions.sessionData.user.has_google_authed;
  },

  addNumbers(numArry) {
    return sum(numArry);
  },

  purgeVOneData() {
    try {
      if (window.localStorage.getItem("replicated.vendor.data") !== null) {
        window.localStorage.removeItem("replicated.vendor.data");
      }
      if (window.localStorage.getItem("replicated.vendor.sessionid") !== null) {
        window.localStorage.removeItem("replicated.vendor.sessionid");
      }
      if (window.sessionStorage.getItem("replicated.vendor.data") !== null) {
        window.sessionStorage.removeItem("replicated.vendor.data");
      }
      if (window.sessionStorage.getItem("replicated.vendor.sessionid") !== null) {
        window.sessionStorage.removeItem("replicated.vendor.sessionid");
      }
    } catch (err) {
      console.log(err);
    }
  },

  getTeamId() {
    const vendorData = window.localStorage["reduxPersist:auth"];
    if (!vendorData) {
      return "";
    }

    const data = JSON.parse(vendorData).sessions.sessionData;

    if (data && data.team) {
      return data.team.id;
    }

    return "";
  },

  buildInstallCommand(scheduler, appSlug, channelName) {
    const scriptBaseUrl = import.meta.env.VITE_INSTALL_SCRIPTS_ENDPOINT;
    switch (scheduler) {
      case "swarm":
        return `curl -sSL -o install.sh ${scriptBaseUrl}/${appSlug}/${encodeURIComponent(
          channelName
        )}/swarm-init\nsudo bash ./install.sh`;
      case "kubernetes":
        return `curl -sSL -o install.sh ${scriptBaseUrl}/${appSlug}/${encodeURIComponent(
          channelName
        )}/kubernetes-init\nsudo bash install.sh`;
      default:
        return `curl -sSL -o install.sh ${scriptBaseUrl}/docker/${appSlug}/${encodeURIComponent(
          channelName
        )}\nsudo bash ./install.sh`;
    }
  },

  buildDocsLink(scheduler) {
    switch (scheduler) {
      case "swarm":
        return "https://help.replicated.com/guides/ship-with-docker-swarm/installing/";
      case "kubernetes":
        return "https://help.replicated.com/guides/ship-with-kubernetes/install/";
      default:
        return "https://help.replicated.com/guides/native-scheduler/install/";
    }
  },

  calcPercent(num1, num2) {
    const percentage = (num1 / num2) * 100;
    if (isFinite(percentage)) {
      return percentage.toFixed(2);
    } else {
      return 0;
    }
  },

  isSameDate(date, compareDate) {
    return moment(date).isSame(compareDate);
  },

  dateFormat(date, format, localize = true) {
    if (!localize) {
      return moment.utc(date).format(format);
    }
    return moment.utc(date).local().format(format);
  },

  dateAndYearValid(date) {
    if (isEmpty(date)) {
      return false;
    }

    return moment(date).year() > 1;
  },

  isValidDate(date) {
    const exp =
      /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return exp.test(date);
  },

  checkIsDateExpired(date) {
    const formattedDate = new Date(date).toISOString();
    const currentDate = moment.utc();
    const expirationDate = moment.utc(formattedDate);

    return currentDate.isAfter(expirationDate);
  },

  isExpiringSoon(date) {
    const currentDate = moment.utc();
    const expirationDate = moment.utc(date);
    const diff = expirationDate.diff(currentDate, "days");
    if (diff <= 30 && diff >= 0) {
      return true;
    }
    return false;
  },

  determineCardClass(name) {
    switch (name) {
      case "Stable":
        return "stable";
      case "Beta":
        return "beta";
      case "Unstable":
        return "unstable";
      default:
        return "other";
    }
  },

  determineAirgapClass(status) {
    switch (status) {
      case "queued":
        return "other";
      case "ready":
        return "active";
      case "error":
        return "inactive";
      case "unknown":
        return "inactive";
      default:
        return "in-process";
    }
  },

  determineKotsAirgapClass(status) {
    switch (status) {
      case "built":
        return "active";
      case "failed":
        return "inactive";
      case "building":
        return "in-process";
      default:
        return "pending";
    }
  },

  getLicenseType(type) {
    if (type === "not-set") {
      return "---";
    }
    if (type === "prod") {
      return "paid";
    }
    return type;
  },

  getAirgapStatus(status) {
    const statusList = {
      queued: "queued",
      deferred: "deferred",
      building: "building",
      rebuilding: "rebuilding",
      updating: "updating",
      ready: "built",
      error: "failed",
      unknown: "not built"
    };
    return statusList[status];
  },

  tokenValue(token) {
    return token.replace("Bearer ", "");
  },

  hasAdoptionRate(current, previous, other) {
    const currentCount = this.addNumbers(values(current));
    const prevCount = this.addNumbers(values(previous));
    const otherCount = this.addNumbers(values(other));
    const total = currentCount + prevCount + otherCount;

    return total > 0;
  },

  entitlementValue(token, key) {
    const parsedToken = decodeJwt(this.tokenValue(token));
    if (!has(parsedToken, "entitlements")) {
      // migrating existing session
      if (key == "has_contract") {
        return parsedToken.has_contract;
      } else {
        return false;
      }
    }

    return parsedToken.entitlements[key];
  },

  hasLicenses(licenses) {
    if (!licenses) {
      return;
    }
    const total = this.addNumbers(values(licenses));
    return total > 0;
  },

  isFeatureEnabled(key) {
    const queryClient = getQueryClient();
    const featureData = queryClient.getQueryData<FeaturesResponse>(["features"]);
    return featureData?.features?.find(feature => feature.Key === key)?.Value === "1";
  },

  getReleaseLabel(label, sequence) {
    if (sequence === -1) {
      return "---";
    }
    if (label === "") {
      return "Unnamed release";
    }
    return label;
  },

  determineCurrentApp(appList, newId) {
    return find(appList, { Id: newId });
  },

  getTriggerObject(triggerRule) {
    if (!triggerRule.length) {
      return {
        category: "Unsupported",
        event: "Unsupported trigger"
      };
    }
    if (triggerRule.length > 1) {
      const valueArray = map(triggerRule, "value");
      switch (true) {
        case valueArray.includes("license.online") &&
          valueArray.includes("license.offline"):
          return {
            category: "Licenses",
            event: "Licenses comes online or goes offline"
          };
        default:
          return {
            category: "Unsupported",
            event: "Unsupported trigger"
          };
      }
    }
    switch (triggerRule[0].value) {
      case "license.online":
        return {
          category: "Licenses",
          event: "License Comes online"
        };
      case "license.offline":
        return {
          category: "Licenses",
          event: "License goes offline"
        };
      case "release.archive":
        return {
          category: "Release",
          event: "Application release is deleted"
        };
      case "release.promote":
        return {
          category: "Release",
          event: "Application release is promoted"
        };
      default:
        return {
          category: "Unsupported",
          event: "Unsupported trigger"
        };
    }
  },

  getPreflightTitle(name) {
    switch (name) {
      case "cpu_cores":
        return "CPU Cores";
      case "cpu_mhz":
        return "CPU Megahertz";
      case "memory":
        return "Memory";
      case "disk_space":
        return "Disk Space";
      case "custom_requirements":
        return "Custom Preflight Checks";
      case "replicated_version":
        return "Replicated Version";
      case "docker_version":
        return "Docker Version";
      case "networking":
        return "Network Connection";
      default:
        return "Requirement";
    }
  },

  validReplicatedUrl(url) {
    switch (url) {
      case "customers":
        return true;
      case "audit-log":
        return true;
      case "changelog":
        return true;
      case "releases":
        return true;
      case "channels":
        return true;
      case "licenses":
        return true;
      case "license-fields":
        return true;
      case "images":
        return true;
      case "settings":
        return true;
      case "support":
        return true;
      case "require-2fa":
        return true;
      case "account-settings":
        return true;
      case "team":
        return true;
      case "troubleshoot":
        return true;
      case "new-application":
        return true;
      case "privacy":
        return true;
      case "terms":
        return true;
      default:
        return false;
    }
  },

  isEmailValid(email) {
    if (!email) {
      return false;
    }

    const exp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return exp.test(email);
  },

  isEmailAlias(email) {
    return email.includes("+") ? true : false;
  },

  isCloudCMXDistribution(distro) {
    return distro === "eks" || distro === "gke" || distro === "aks" || distro === "oke";
  },

  // Converts string to titlecase i.e. 'hello' -> 'Hello'
  // @returns {String}
  toTitleCase(word) {
    if (typeof word !== "string") {
      return "";
    }

    let i, j, str;
    str = word.replace(/([^\W_]+[^\s-]*) */g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    // Certain minor words should be left lowercase unless
    // they are the first or last words in the string
    const lowers = [
      "A",
      "An",
      "The",
      "And",
      "But",
      "Or",
      "For",
      "Nor",
      "As",
      "At",
      "By",
      "For",
      "From",
      "In",
      "Into",
      "Near",
      "Of",
      "On",
      "Onto",
      "To",
      "With"
    ];
    for (i = 0, j = lowers.length; i < j; i++) {
      str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), txt => {
        return txt.toLowerCase();
      });
    }

    // Certain words such as initialisms or acronyms should be left uppercase
    const uppers = ["Id", "Tv"];
    for (i = 0, j = uppers.length; i < j; i++) {
      str = str.replace(
        new RegExp("\\b" + uppers[i] + "\\b", "g"),
        uppers[i].toUpperCase()
      );
    }

    return str;
  },

  getMemberPolicy(token) {
    const session: Session = decodeJwt(this.tokenValue(token));
    const policies = session.policies.map(policy => {
      return JSON.parse(policy);
    });
    return policies;
  },

  // Backwards compat with current read/write checks
  isReadOnlyToken(token) {
    const session = decodeJwt(this.tokenValue(token));
    const rawPolicy = session.policies?.[0];
    const policy = rawPolicy && JSON.parse(rawPolicy);

    if (!policy) {
      return true;
    }

    const readOnlyAllowed = new Set(["**/list", "**/read"]);
    const policyAllowed = new Set(policy.v1.resources.allowed);
    const readOnly =
      isEqual(policyAllowed, readOnlyAllowed) &&
      policy.v1.resources.denied.includes("**/*");
    return readOnly;
  },

  isResourceAccessAllowed(resource?: string): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    const session: Session = decodeJwt(this.tokenValue(this.getToken()));
    if (!session) {
      return false;
    }
    if (!resource) {
      return !this.isReadOnlyToken(token);
    }

    const policies = session.policies.map(policy => {
      return JSON.parse(policy);
    });

    const isAllowed = policies.some(policy => {
      const { allowed, denied } = policy?.v1?.resources || {};

      // Typescript optional chaining doesn't handle false properly
      const makeRe = reString => {
        const regex = globToRegExp(reString, { globstar: true });
        return regex ? regex : undefined;
      };

      // check if there's a policy that matches the resource for both allowed and denied
      const allowedMatch = allowed?.find(p =>
        globToRegExp(p, { globstar: true }).test(resource)
      );
      const deniedMatch = denied?.find(p =>
        globToRegExp(p, { globstar: true }).test(resource)
      );

      if (deniedMatch && allowedMatch) {
        // if the resource matches a policy in both allowed and denied
        // determine which policy is more specific by seeing if one policy is a subset of the other
        const deniedIsSubset = globToRegExp(allowedMatch, { globstar: true }).test(
          deniedMatch
        );
        const allowedIsSubset = globToRegExp(deniedMatch, { globstar: true }).test(
          allowedMatch
        );

        if (deniedIsSubset) {
          // if the denied policy is a subset of the allowed policy, then the denied policy is more specific
          // and we should return false that the user is not allowed to perform the resource action
          return false;
        }

        if (allowedIsSubset) {
          // if the allowed policy is a subset of the denied policy, then the allowed policy is more specific
          //  and we should return true that the user is allowed to perform the resource action
          return true;
        }
      }

      return (
        allowed?.some(p => makeRe(p).test(resource)) ||
        !denied?.some(p => makeRe(p).test(resource))
      );
    });

    return isAllowed;
  },

  isErrorForbidden(error) {
    const message = error?.message;

    if (!message) {
      return false;
    }

    return (
      message.includes("403") ||
      message.includes("401") ||
      /forbidden|denied/i.test(message)
    );
  },

  semverVersionsCompare(version1, version2) {
    const [v1, v1Suffix] = version1.split("-");
    const [v2, v2Suffix] = version2.split("-");

    let diff;
    const regExStrip0 = /(\.0+)+$/;
    const segmentsA = v1.replace(regExStrip0, "").split(".");
    const segmentsB = v2.replace(regExStrip0, "").split(".");
    const l = Math.min(segmentsA.length, segmentsB.length);

    for (let i = 0; i < l; i++) {
      diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
      if (diff) {
        return diff;
      }
    }

    if (segmentsA.length === segmentsB.length) {
      return (v1Suffix || "").localeCompare(v2Suffix || "");
    }

    return segmentsA.length - segmentsB.length;
  },

  rootPath(path) {
    if (path[0] !== "/") {
      return (path = "/" + path);
    } else {
      return path;
    }
  },

  retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch(error => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              reject(error);
              return;
            }

            VendorUtilities.retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  },

  isConfigFile(file) {
    if (!file || !file?.content) {
      return false;
    }
    try {
      const content: any = yaml.load(file.content);
      return content?.apiVersion === "kots.io/v1beta1" && content?.kind === "Config";
    } catch (err) {
      return false;
    }
  },

  getLineChanges(lineChangesArr) {
    let addedLines = 0;
    let removedLines = 0;
    lineChangesArr.forEach(lineChange => {
      const {
        originalStartLineNumber,
        originalEndLineNumber,
        modifiedStartLineNumber,
        modifiedEndLineNumber
      } = lineChange;

      if (
        originalEndLineNumber === originalStartLineNumber &&
        modifiedEndLineNumber === modifiedStartLineNumber &&
        originalEndLineNumber &&
        modifiedEndLineNumber
      ) {
        addedLines++;
        removedLines++;
      } else {
        if (
          modifiedEndLineNumber > modifiedStartLineNumber ||
          originalEndLineNumber === 0
        ) {
          addedLines += modifiedEndLineNumber - modifiedStartLineNumber + 1;
        }
        if (
          originalEndLineNumber > originalStartLineNumber ||
          modifiedEndLineNumber === 0
        ) {
          removedLines += originalEndLineNumber - originalStartLineNumber + 1;
        }
      }
    });
    return {
      addedLines: addedLines,
      removedLines: removedLines,
      changes: addedLines + removedLines
    };
  },

  isTopSectionFile(file) {
    if (file?.name?.endsWith(".css")) return true;

    const topSectionFiles = [
      {
        kind: "Config",
        apiVersion: "kots.io/v1beta1"
      },
      {
        kind: "Application",
        apiVersion: "kots.io/v1beta1"
      },
      {
        kind: "Identity",
        apiVersion: "kots.io/v1beta1"
      },
      {
        kind: "LintConfig",
        apiVersion: "kots.io/v1beta1"
      },
      {
        kind: "Application",
        apiVersion: "app.k8s.io/v1beta1"
      },
      {
        kind: "Collector",
        apiVersion: "troubleshoot.replicated.com/v1beta1"
      },
      {
        kind: "Preflight",
        apiVersion: "troubleshoot.replicated.com/v1beta1"
      },
      {
        kind: "Analyzer",
        apiVersion: "troubleshoot.replicated.com/v1beta1"
      },
      {
        kind: "SupportBundle",
        apiVersion: "troubleshoot.replicated.com/v1beta1"
      },
      {
        kind: "Collector",
        apiVersion: "troubleshoot.sh/v1beta2"
      },
      {
        kind: "Preflight",
        apiVersion: "troubleshoot.sh/v1beta2"
      },
      {
        kind: "Analyzer",
        apiVersion: "troubleshoot.sh/v1beta2"
      },
      {
        kind: "SupportBundle",
        apiVersion: "troubleshoot.sh/v1beta2"
      },
      {
        kind: "Redactor",
        apiVersion: "troubleshoot.sh/v1beta2"
      },
      {
        kind: "Backup",
        apiVersion: "velero.io/v1"
      },
      {
        kind: "Installer",
        apiVersion: "kurl.sh/v1beta1"
      },
      {
        kind: "Installer",
        apiVersion: "cluster.kurl.sh/v1beta1"
      },
      {
        kind: "Config",
        apiVersion: "embeddedcluster.replicated.com/v1beta1"
      }
    ];
    try {
      const content: any = yaml.load(file.content);
      if (
        content?.kind &&
        content?.apiVersion &&
        find(topSectionFiles, { kind: content.kind, apiVersion: content.apiVersion })
      ) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  },

  parseIconUri(uri) {
    const splitUri = uri.split("?");
    if (splitUri.length < 2) {
      return {
        uri: "https://troubleshoot.sh/images/analyzer-icons/gray-checkmark.svg",
        dimensions: {
          w: 17,
          h: 17
        }
      };
    }
    return {
      uri: splitUri[0],
      dimensions: queryString.parse(splitUri[1])
    };
  },

  isFileTarGz(name?: string) {
    if (!name) {
      return false;
    }
    return name.endsWith(".tgz") || name.endsWith(".tar.gz");
  },

  isFontFile(name) {
    return (
      name.endsWith(".woff") ||
      name.endsWith(".woff2") ||
      name.endsWith(".ttf") ||
      name.endsWith(".otf") ||
      name.endsWith(".eot") ||
      name.endsWith(".svg")
    );
  },

  getNameWithoutExtension(name) {
    return name.substr(0, name.lastIndexOf("."));
  },

  getExtension(name) {
    return name.substr(name.lastIndexOf(".") + 1, name.length);
  },

  getNameWithoutTarGzExtension(name) {
    if (!this.isFileTarGz(name)) {
      return name;
    }
    if (name.endsWith(".tgz")) {
      return name.substring(0, name.indexOf(".tgz"));
    }
    return name.substring(0, name.indexOf(".tar.gz"));
  },

  getNextAvailableName(files, name) {
    try {
      let existingMatches = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!this.isFileTarGz(file.name)) {
          continue;
        }
        existingMatches = existingMatches.concat(
          file.children.filter(item => item.name.includes(name))
        );
      }

      if (!existingMatches.length) {
        return name;
      }

      let suffix = 1;
      existingMatches.forEach(match => {
        const matchNameWithoutExtension = this.getNameWithoutExtension(match.name);
        const matchNumber = matchNameWithoutExtension.split("-").pop();
        if (!isNaN(matchNumber) && parseInt(matchNumber) >= suffix) {
          suffix = parseInt(matchNumber) + 1;
        }
      });

      return `${name}-${suffix}`;
    } catch (err) {
      console.log(err);
      return name;
    }
  },

  getHelmChartManifest(files, chart) {
    for (let f = 0; f < files.length; f++) {
      const file = files[f];
      if (file.children.length > 0) {
        const manifest = this.getHelmChartManifest(file.children, chart);
        if (manifest) {
          return manifest;
        }
      } else if (!this.isFileTarGz(file.name)) {
        try {
          const content: any = yaml.load(file.content);
          if (
            content.apiVersion === "kots.io/v1beta1" &&
            content.kind === "HelmChart" &&
            content.spec?.chart?.name === chart.name &&
            content.spec?.chart?.chartVersion === chart.version
          ) {
            return file;
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
    return null;
  },

  createHelmChartManifest(
    files: any,
    chart: { name: any; apiVersion: string; version: any },
    kotsApiVersion: "v1beta1" | "v1beta2"
  ) {
    const copyName = this.getNextAvailableName(files, chart.name);

    const helmVersion = "v3";
    const useHelmInstall = true;

    const manifestContent = `apiVersion: kots.io/${kotsApiVersion}
kind: HelmChart
metadata:
  name: ${copyName}
spec:
  # chart identifies a matching chart from a .tgz
  chart:
    name: ${chart.name}
    chartVersion: ${chart.version}
  ${
    kotsApiVersion === "v1beta1"
      ? `
  # helmVersion identifies the Helm Version used to render the Chart.
  helmVersion: ${helmVersion}

  # useHelmInstall identifies whether this Helm chart will use the
  # Replicated Helm installation (false) or native Helm installation (true). Default is false.
  # Native Helm installations are only available for Helm v3 charts.
  useHelmInstall: ${useHelmInstall}
      `
      : ""
  }
  # values are used in the customer environment, as a pre-render step
  # these values will be supplied to helm template
  values: {}

  # builder values provide a way to render the chart with all images
  # and manifests. this is used in replicated to create air gap packages
  builder: {}
`;
    const helmManifest = {
      name: `${copyName}.yaml`,
      path: `${copyName}.yaml`,
      content: manifestContent,
      children: []
    };
    return helmManifest;
  },

  isHelmManifest(file) {
    try {
      if (!this.isFileTarGz(file.name)) {
        const content: any = yaml.load(file.content);
        if (
          (content?.apiVersion === "kots.io/v1beta1" ||
            content?.apiVersion === "kots.io/v1beta2") &&
          content?.kind === "HelmChart"
        ) {
          return true;
        }
      }
    } catch (err) {
      // ignore - happens when editing files and the yaml is invalid
    }
    return false;
  },

  isHelmFile(file) {
    return (
      (file?.path.includes(".tgz") || file?.path.includes(".tar.gz")) &&
      !this.isHelmManifest(file)
    );
  },

  calculateTwoDecimalsPatchSequence(sequence, patchSequence) {
    if (patchSequence === 0) {
      return sequence;
    }

    // Max patch sequence is 99 since we round to two decimal places
    if (patchSequence >= 100) {
      return 0;
    }
    return sequence + patchSequence / 100;
  },

  calculateSequenceFromString(s) {
    return Math.floor(parseFloat(s));
  },

  calculatePatchSequenceFromString(s) {
    return Math.round(parseFloat(s) * 100) % 100;
  },

  groupManifestsWithHelmCharts(spec) {
    try {
      for (let f = 0; f < spec.length; f++) {
        const manifest = spec[f];
        if (VendorUtilities.isHelmManifest(manifest)) {
          const manifestContent: any = yaml.load(manifest.content);
          const helm = find(spec, helmChart => {
            if (!VendorUtilities.isFileTarGz(helmChart.name)) {
              return false;
            }
            try {
              const chartFile = find(helmChart.children, { name: "Chart.yaml" });
              const chart: any = yaml.load(chartFile?.content);
              return (
                manifestContent?.spec?.chart?.name === chart.name &&
                manifestContent?.spec?.chart?.chartVersion === chart.version
              );
            } catch (err) {
              console.log(err);
              return false;
            }
          });
          if (helm) {
            spec.splice(f, 1);
            helm.children.push(manifest);
            f--;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    return spec;
  },

  extractHelmChartsAndValues(spec) {
    try {
      for (let f = 0; f < spec.length; f++) {
        const file = spec[f];
        if (VendorUtilities.isFileTarGz(file.name)) {
          this.createChartChildren(file, "Chart.yaml", "values.yaml");
        }
      }
    } catch (err) {
      console.log(err);
    }
    return spec;
  },

  createChartChildren(tarGzFile, ...fileNames) {
    const entries = this.extract(tarGzFile);
    const files = {};
    for (const name of fileNames) {
      for (const entry of entries) {
        const parts = entry.fileName.split("/");
        if (parts.length === 2 && parts[1] === name) {
          const content = new TextDecoder().decode(entry.content);
          const chartFile = {
            name,
            path: `${tarGzFile.path}/${name}`,
            content,
            children: []
          };
          if (!tarGzFile.children) {
            tarGzFile.children = [];
          } else {
            tarGzFile.children.push(chartFile);
          }
          files[parts[1]] = content;
          break;
        }
      }
    }
    return files;
  },

  extract(tarGzFile): "" | TarEntry[] {
    if (!tarGzFile?.content || !this.isFileTarGz(tarGzFile.name)) {
      return "";
    }

    const buffer = new Uint8Array(tarGzFile?.content);
    const tar = pako.ungzip(buffer);
    return Tarball.extract(tar);
  },

  async generateSpec(files) {
    const spec = [];

    for (const file of files) {
      const specFile = { ...file };
      if (VendorUtilities.isFileTarGz(specFile.name || file.name)) {
        if (typeof specFile.content === "object") {
          specFile.content = await this.base64EncodeBuffer(specFile.content);
        }
        // filter out chart/values files from specfile.children's array
        // filter out helm chart file from specfile.children's array but add it to the spec array
        specFile.children = specFile.children.filter(currentChild => {
          if (VendorUtilities.isHelmManifest(currentChild)) {
            // include helmchart file in spec array
            spec.push(currentChild);
            // Exclude from filtered array
            return false;
          }
          // exclude chart and values file from spec
          return !["Chart.yaml", "values.yaml"].includes(currentChild?.name);
        });
      }
      spec.push(specFile);
    }

    return spec;
  },

  base64EncodeBuffer(content) {
    return new Promise(resolve => {
      const blob = new Blob([content]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        const base64String = (reader.result as string).split(",")[1];
        resolve(base64String);
      };
    });
  },

  // TODO delete me?
  removeChartFiles(tarGzFile) {
    for (let c = 0; c < tarGzFile.children.length; c++) {
      const child = tarGzFile.children[c];
      if (child.name === "Chart.yaml") {
        tarGzFile.children.splice(c, 1);
      }
      if (child.name === "values.yaml") {
        tarGzFile.children.splice(c, 1);
      }
    }
  },

  sortAnalyzers(bundleInsight) {
    return sortBy(bundleInsight, item => {
      switch (item.level) {
        case "error":
          return 1;
        case "warn":
          return 2;
        case "info":
          return 3;
        case "debug":
          return 4;
        default:
          return 1;
      }
    });
  },

  getFileFormat(selectedFile) {
    if (selectedFile === "") {
      return "text";
    }
    if (selectedFile.includes(".json")) {
      return "json";
    } else if (selectedFile.includes(".human")) {
      return "yaml";
    }
    return "text";
  },

  isApplicationFile(file) {
    if (!file) {
      return false;
    }
    try {
      const content: any = yaml.load(file);
      return (
        content?.apiVersion === "kots.io/v1beta1" && content?.kind === "Application"
      );
    } catch (err) {
      return false;
    }
  },

  filterActiveKotsInstallers(allInstallers, appChannels) {
    return allInstallers?.filter(installer => {
      // TODO: remove this 'some' (and lodash 'some' import) once only active channels are returned from the API
      const hasChannel = appChannels.some(({ id }) =>
        installer.channelIds.includes(id)
      );
      return hasChannel && installer.channelIds.length > 0;
    });
  },

  convertToReadableString(string) {
    return startCase(string);
  }
};

function apiErrorCodeToError(errorCode) {
  switch (errorCode) {
    case "CUSTOMER_CHANNEL_ERROR":
      return `You are attempting to assign a KOTS-enabled customer to a channel with a Helm-only head release. To resolve this, you can either
      1. Disable KOTS installations for this customer by unchecking the KOTS Install box
      2. Assign this customer to a different channel that includes a KOTS-capable release
      3. Promote a release containing KOTS manifests to this channel (it will still be installable with the Helm CLI as long as it contains a helm chart)`;
    default:
      return null;
  }
}

/**
 * Fetcher function for the Unified API
 *
 * @param path - the path to fetch
 * @param config - request config (custom, not passed directly to fetch)
 * @param config.body - request body
 * @param config.enableErrorHandling (default: true) whether to use default error handling, if set to false the response is always returned immediately
 * @param config.endpoint - (default: VITE_API_ENDPOINT + "/v1") the API endpoint to use
 * @param config.errorMessage - custom error message to throw if response is not ok
 * @param config.headers - custom headers to send, spread over default headers
 * @param config.method - (default: "GET") the HTTP method to use
 * @returns the response
 *
 * @example
 * ```typescript
 * const response = await apiFetch("/app/myapp");
 * const json = await response.json();
 * ```
 *
 * @remarks
 * This function extends fetch to cut down on boilerplate
 * - adds default Unified API endpoint
 * - adds Authorization and default headers
 * - adds default method
 * - throws if response is not ok
 */
export const apiFetch = async (
  path: string,
  {
    body,
    enableErrorHandling = true,
    endpoint = endpoints.api,
    errorMessage,
    headers = {},
    method = "GET",
    includeAuth = true
  }: {
    body?: string;
    enableErrorHandling?: boolean;
    endpoint?: string;
    errorMessage?: string;
    headers?: HeadersInit;
    method?: string;
    includeAuth?: boolean;
  } = {}
) => {
  const url = `${endpoint}${path.startsWith("/") ? "" : "/"}${path}`;
  const token = VendorUtilities.getToken();
  const response = await fetch(url, {
    method,
    headers: {
      ...(includeAuth && token && { Authorization: VendorUtilities.getToken() }),
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    },
    ...(body ? { body } : {})
  });
  if (response.ok || !enableErrorHandling) {
    return response;
  }
  if (response.status === 401) {
    getStore().dispatch(logOutUser() as unknown as AnyAction);
  }
  if (response.status === 403) {
    const errorMessage = (await response.text()) || "Forbidden";
    throw new Error(
      "It looks like you might not have permission to do that - please contact your account administrator(s).",
      {
        cause: new Error(errorMessage)
      }
    );
  }
  if (errorMessage) {
    throw new Error(errorMessage);
  }

  const clone = response.clone();
  const error = await response.json().catch(() => clone.text());

  if (!error && response.status === 404) {
    throw new Error("Not found");
  }

  const message = apiErrorCodeToError(error?.error_code);

  throw new Error(
    message ||
      error?.error?.message ||
      error?.error ||
      error?.message ||
      error?.Error?.message ||
      (error?.validateError ? JSON.stringify(error) : "Something went wrong")
  );
};

/**
 * Fetcher function for V3 Vendor API
 *
 * Wraps {@link apiFetch} and matches its signature, but passes in the V3 Vendor API endpoint
 */
export const apiFetchVendor: typeof apiFetch = async (path, options = {}) => {
  return apiFetch(path, {
    ...options,
    endpoint: endpoints.vendor
  });
};

/**
 * Fetcher function for V1 Vendor API
 *
 * Wraps {@link apiFetch} and matches its signature, but passes in the V1 Vendor API endpoint
 */
export const apiFetchVendorV1: typeof apiFetch = async (path, options = {}) => {
  return apiFetch(path, {
    ...options,
    endpoint: endpoints.vendorV1
  });
};

export const formatTimestamp = timestamp => {
  return formatDate(new Date(timestamp), "MM/dd/yyyy @ h:mmaaa");
};

export const notifyError = error => {
  console.error(error);
  if (import.meta.env.VITE_BUGSNAG_API_KEY) {
    Bugsnag.notify(error);
  }
};

export const gzipData = (data: any) => {
  return Buffer.from(gzip(JSON.stringify(data))).toString("base64");
};

export const formatToGoDurationWithoutSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let result = "";
  if (hours > 0) {
    result += `${hours}h`;
  }
  if (minutes > 0 || hours > 0) {
    result += `${minutes}m`;
  }

  return result || "0m";
};

export const golangDurationToMinutes = (duration: string): number => {
  const regex = /(\d+)([hms])/g;
  let totalMinutes = 0;

  const matches = Array.from(duration.matchAll(regex));
  matches.forEach(([, value, unit]) => {
    const intValue = parseInt(value);
    if (unit === "h") {
      totalMinutes += intValue * 60;
    } else if (unit === "m") {
      totalMinutes += intValue;
    } else if (unit === "s") {
      totalMinutes += intValue / 60;
    }
  });

  return Math.round(totalMinutes);
};

export const golangDurationToEnglish = (duration: string): string => {
  const regex = /(\d+)([hms])/g;
  const units: { [key: string]: string } = {
    h: "hour",
    m: "minute",
    s: "second"
  };

  const matches = Array.from(duration.matchAll(regex));
  const parts = matches.map(([, value, unit]) => {
    const intValue = parseInt(value);
    const unitName = units[unit];
    return `${intValue} ${unitName}${intValue > 1 ? "s" : ""}`;
  });

  let totalHours = 0;
  matches.forEach(([, value, unit]) => {
    const intValue = parseInt(value);
    if (unit === "h") {
      totalHours += intValue;
    } else if (unit === "m") {
      totalHours += intValue / 60;
    } else if (unit === "s") {
      totalHours += intValue / 3600;
    }
  });

  const totalDays = totalHours / 24;
  if (Number.isInteger(totalDays) && totalDays > 2) {
    return `${totalDays} days`;
  }

  return parts.join(", ");
};
